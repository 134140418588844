import { createActionGroup, props } from '@ngrx/store';
import { PaginationRequestDTO, UploadFileRequestDTO } from '../../application/dtos/mass-transfer/uploaded-file.dto';
import { UploadedFile } from '../../domain/entities/mass-transfer/uploaded-file.entity';
import { BulkPaymentRecord } from '../../domain/entities/mass-transfer/bulk-payment-record.entity';


export const MassTransferActions = createActionGroup({
	source: 'TransferManagement',
	events: {
		'Upload File': props<{ request: UploadFileRequestDTO }>(),
		'Upload File Success': props<{ response: { confirmationNumber: string; sysGeneratedFileName: string; } }>(),
		'Upload File Failure': props<{ error: any }>(),

		'Fetch Uploaded Files': props<{ request: PaginationRequestDTO }>(),
		'Fetch Uploaded Files Success': props<{ response: UploadedFile[] }>(),
		'Fetch Uploaded Files Failure': props<{ error: any }>(),

		'Fetch on going payment': props<{ request: PaginationRequestDTO }>(),
		'Fetch on going payment Success': props<{ response: BulkPaymentRecord[] }>(),
		'Fetch on going payment Failure': props<{ error: any }>(),

		'Fetch payment history': props<{ request: PaginationRequestDTO }>(),
		'Fetch payment history Success': props<{ response: BulkPaymentRecord[] }>(),
		'Fetch payment history Failure': props<{ error: any }>(),

		'review bulk payment record': props<{ recordId: string }>(),
		'review bulk payment record Success': props<{ response: any }>(),
		'review bulk payment record Failure': props<{ error: any }>(),
	},
});
