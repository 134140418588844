import { createFeature, createReducer, on } from "@ngrx/store";
import { MassTransferActions } from "../actions/mass-transfer.actions";
import { UploadedFile } from "../../domain/entities/mass-transfer/uploaded-file.entity";
import { BulkPaymentRecord } from "../../domain/entities/mass-transfer/bulk-payment-record.entity";

export interface MassTransferState {
	fileUploaded: {confirmationNumber: string;sysGeneratedFileName: string;};
	uploadedFiles: UploadedFile[];
	bulkPaymentRecords: BulkPaymentRecord[];
	bulkPaymentRecordHistory: BulkPaymentRecord[];
	loading: boolean;
	error: any;
}

export const initialState: MassTransferState = {
	fileUploaded: {confirmationNumber: '',sysGeneratedFileName: ''},
	uploadedFiles: [],
	bulkPaymentRecords: [],
	bulkPaymentRecordHistory: [],
	loading: false,
	error: null
};

const massTransferFeature = createFeature({
	name: 'massTransfer',
	reducer: createReducer(
		initialState,
		on(
			MassTransferActions.uploadFile,
			MassTransferActions.fetchUploadedFiles,
			MassTransferActions.fetchOnGoingPayment,
			MassTransferActions.fetchPaymentHistory,
			MassTransferActions.reviewBulkPaymentRecord,
			(state: MassTransferState): MassTransferState => ({
				...state,
				loading: true
			})
		),

		on(
			MassTransferActions.uploadFileSuccess,
			(state: MassTransferState, { response }): MassTransferState => ({
				...state,
				fileUploaded: {
					confirmationNumber: response.confirmationNumber,
					sysGeneratedFileName: response.sysGeneratedFileName
				},
				loading: false,
			})
		),

		on(
			MassTransferActions.fetchUploadedFilesSuccess,
			(state: MassTransferState, { response }): MassTransferState => ({
				...state,
				uploadedFiles: response,
				loading: false,
				error: null
			})
		),

		on(
			MassTransferActions.fetchOnGoingPaymentSuccess,
			(state: MassTransferState, { response }): MassTransferState => ({
				...state,
				bulkPaymentRecords: response,
				loading: false,
				error: null
			})
		),

		on(
			MassTransferActions.fetchPaymentHistorySuccess,
			(state: MassTransferState, { response }): MassTransferState => ({
				...state,
				bulkPaymentRecordHistory: response,
				loading: false,
				error: null
			})
		),

		on(
			MassTransferActions.reviewBulkPaymentRecordSuccess,
			(state: MassTransferState, { response }): MassTransferState => ({
				...state,
				// bulkPaymentRecord: response,
				loading: false,
				error: null
			})
		),

		on(
			MassTransferActions.uploadFileFailure,
			MassTransferActions.fetchUploadedFilesFailure,
			MassTransferActions.fetchOnGoingPaymentFailure,
			MassTransferActions.fetchPaymentHistoryFailure,
			MassTransferActions.reviewBulkPaymentRecordFailure,
			(state: MassTransferState, { error }): MassTransferState => ({
				...state,
				error,
				loading: false,
			})
		)
	)
});

export const {
	name: massTransferFeatureKey,
	reducer: massTransferReducer,
	selectLoading,
	selectError,
	selectUploadedFiles,
	selectFileUploaded,
	selectBulkPaymentRecords
} = massTransferFeature;



